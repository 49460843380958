@keyframes scrollTop {
    from {
        transform: translateY(2px);
    }
    to {
        transform: translateY(-1px);
    }
}

.scroll-to-top {
    position: fixed;
    bottom: 25px;
    right: 3%;
    width: 40px;
    padding: 10;
    height: 40px;
    border-radius: 100%;
    background: #D71E3D;
    color: #fff;
    z-index: 1;

}

.scroll-to-top .icon {
    margin-top: 8px;
    animation: scrollTop 0.5s alternate ease infinite;
    justify-content: center;
    margin-left: 6px;
} 
