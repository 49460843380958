.mas:hover {
  background: black;
  transform: scale(2); /* Equal to scaleX(2) scaleY(2) */
}
@keyframes TransitioningBackground {
  0% {
    background-position: 1% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 1% 0%;
  }
}
